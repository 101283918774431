import accounting    from "accounting"
import Decimal       from "decimal.js"
import VentaServices from "../../Services/VentaServices"

const ventaServices = new VentaServices();

export default {
  precioCodigoProducto(producto, codigoPrecio) {
    switch (codigoPrecio) {
      case 101:
        return producto.precio1;
      case 102:
        return producto.precio2;
      case 103:
        return producto.precio3;
      case 104:
        return producto.precio4;
      case 105:
        return producto.precio5;
      case 106:
        return producto.precio6;
    }
  },
  obtenerListaPreciosProducto(producto) {
    let precio1 = producto.precio1,
        precio2 = producto.precio2,
        precio3 = producto.precio3,
        precio4 = producto.precio4,
        precio5 = producto.precio5,
        precio6 = producto.precio6;

    return [
      {label: `Precio 1 - ${accounting.formatMoney(precio1)}`, value: 101},
      {label: `Precio 2 - ${accounting.formatMoney(precio2)}`, value: 102},
      {label: `Precio 3 - ${accounting.formatMoney(precio3)}`, value: 103},
      {label: `Precio 4 - ${accounting.formatMoney(precio4)}`, value: 104},
      {label: `Precio 5 - ${accounting.formatMoney(precio5)}`, value: 105},
      {label: `Precio 6 - ${accounting.formatMoney(precio6)}`, value: 106}
    ];
  },
  verificaPrecioMinProducto(precioMin, precioAplicar, descuentoAplicar) {
    let precioDescuento = this.calculaPrecioDescuento(precioAplicar, descuentoAplicar);
    return precioDescuento.precioDescuento >= precioMin;
  },
  calculaPrecioDescuento(precio, porcentajeDescuento) {
    let p  = new Decimal(porcentajeDescuento);
    let pr = new Decimal(precio);

    let importeDescuento = new Decimal(pr.times(p.dividedBy(100)));
    let precioDescuento  = new Decimal(pr.minus(importeDescuento));
    return {importeDescuento, precioDescuento};
  },
  sumatoriaProductos(ventaDetalle, descuentoVentaAplicado, configImpuestos) {    
    //return configImpuestos.tipoMetodo === "AUTOMATICO" ? this.sumatoriaProductosSinImpuestos(ventaDetalle, descuentoVentaAplicado)
    //    : this.sumatoriaProductosConImpuestos(ventaDetalle, descuentoVentaAplicado, configImpuestos);
    return this.sumatoriaProductosConImpuestos(ventaDetalle, descuentoVentaAplicado, configImpuestos);
  },
  sumatoriaProductosSinImpuestos(ventaDetalle, descuentoVentaAplicado) {
    ventaDetalle.forEach(producto => {
      let cantidad                 = new Decimal(producto.cantidad),
          precio                   = new Decimal(producto.precio),
          porcentajeDescuento      = new Decimal(_.isEmpty(producto.descuentoPorcentaje) ? descuentoVentaAplicado : producto.descuentoPorcentaje).dividedBy(100),
          subtotal                 = new Decimal(cantidad.times(precio)),
          importeDescuento         = new Decimal(subtotal.times(porcentajeDescuento)),
          importeDescuentoUnitario = new Decimal(precio.times(porcentajeDescuento)),
          subtotalConDesc          = subtotal.minus(importeDescuento),
          subtotalConDescUnitario  = precio.minus(importeDescuentoUnitario),
          importeIeps              = 0,
          importeIepsUnitario      = 0,
          importeIva               = 0,
          importeIvaUnitario       = 0;

      if (producto.iepsAplica) {
        importeIeps         = new Decimal(subtotalConDesc.times(producto.ieps));
        importeIepsUnitario = new Decimal(subtotalConDescUnitario.times(producto.ieps));
      }

      let subtotalConIeps         = subtotalConDesc.plus(importeIeps);
      let subtotalConIepsUnitario = subtotalConDescUnitario.plus(importeIepsUnitario);

      if (!producto.ivaExento) {
        importeIva         = new Decimal(subtotalConIeps.times(producto.iva)).toFixed(2, Decimal.ROUND_DOWN);
        importeIvaUnitario = new Decimal(subtotalConIepsUnitario.times(producto.iva)).toFixed(2, Decimal.ROUND_DOWN);
      }

      let subtotalConImpuesto         = subtotalConIeps.plus(importeIva);
      let subtotalConImpuestoUnitario = subtotalConIepsUnitario.plus(importeIvaUnitario);

      producto.descuento           = importeDescuento;
      producto.importeTotalConIva  = subtotalConImpuesto;
      producto.importeTotal        = subtotalConDesc;
      producto.subtotalUnitario    = subtotalConImpuestoUnitario;
      producto.importeIva          = importeIva;
      producto.importeIeps         = importeIeps;
      producto.importeUnitario     = precio;
      producto.descuentoPorcentaje = porcentajeDescuento * 100;
    });
    return ventaDetalle;
  },
  sumatoriaProductosConImpuestos(ventaDetalle, descuentoVentaAplicado, configImpuestos) {
    ventaDetalle.forEach(producto => {
      let cantidad                 = new Decimal(producto.cantidad),
          precio                   = new Decimal(producto.precio),
          porcentajeDescuento      = new Decimal(_.isEmpty(producto.descuentoPorcentaje) ? descuentoVentaAplicado : producto.descuentoPorcentaje).dividedBy(100);

      let filtros = {
        metodo: configImpuestos.tipoMetodo,
        decimales: configImpuestos.decimales,
        cantidad: cantidad.toString(),
        iva: producto.iva,
        descuento: porcentajeDescuento.toString(),
        ieps: producto.ieps,
        precio: precio.toString(),
        inverso: producto.preciosConImpuestosAgregados
      };
      ventaServices.obtenerImpuestos(filtros)
          .then(lstImpuestos => {
            let impuesto = lstImpuestos[0];
            producto.descuento           = impuesto.conceptoDescuentoSD;
            producto.importeTotalConIva  = impuesto.totalConDescuento;
            producto.importeTotal        = impuesto.subtotalSinDescuento;
            producto.importeIva          = impuesto.impuestoImporteIva;
            producto.importeIeps         = impuesto.impuestoImporteIeps;
            producto.importeUnitarioImp  = impuesto.precioRedondeoImpuetos;
            producto.importeUnitario     = impuesto.valorUnitarioSD;
            producto.precio              = impuesto.precio;
          }).catch(error => console.log(error));

      producto.descuentoPorcentaje = porcentajeDescuento * 100;
    });
    return ventaDetalle;
  },
  obtenerTipoVenta(tipoVenta) {
    switch(tipoVenta) {
      case "Contado": return 100;
      case "Credito": return 101;
      case "Cotizacion": return 102;
    }
  },
  obtenerMsgTipoVenta(tipoVenta) {
    switch(tipoVenta) {
      case "Contado": return "venta de contado";
      case "Credito": return "venta de crédito";
      case "Cotizacion": return "cotización";
    }
  },
  obtenerJsonProductos(ventaDetalle) {
    let vd = ventaDetalle.map(function (detalle) {
      return {
        productoId: detalle.productoId,
        precioLista: detalle.precioLista,
        precio: detalle.importeUnitario,
        cantidadVenta: detalle.cantidad,
        descuento: detalle.descuentoPorcentaje ? new Decimal(detalle.descuentoPorcentaje).dividedBy(new Decimal(100)) : 0,
        tipoDescuento: (detalle.descuentoPorcentaje > 0) ? 1 : 0,
        descripcionExtendida: detalle.descripcion,
        actualizarDescripcion: detalle.permitirCambiarDescripcion,
        tipoCantidad: detalle.tipoCantidad,
        cantidadX: detalle.cantidadX,
        cantidadY: detalle.cantidadY,
        cantidadZ: detalle.cantidadZ,
        cantidadW: detalle.cantidadW,
        preciosConImpuestosAgregados: false
      };
    });
    return JSON.stringify(vd);
  },
  // NUEVA FORMA DE OBTENER VALORES DE PRODUCTO INDIVIDUAL
  sumatoriaProductoIndividual(producto, descuentoVentaAplicado, configImpuestos) {
    return this.sumatoriaProductoConImpuestos(producto, descuentoVentaAplicado, configImpuestos);
  },
  sumatoriaProductoConImpuestos(producto, descuentoVentaAplicado, configImpuestos) {
    
    let cantidad                 = new Decimal(producto.cantidad),
        precio                   = new Decimal(producto.precio),
        porcentajeDescuento      = new Decimal(_.isEmpty(producto.descuentoPorcentaje) ? descuentoVentaAplicado : producto.descuentoPorcentaje).dividedBy(100);

    let filtros = {
      metodo: configImpuestos.tipoMetodo,
      decimales: configImpuestos.decimales,
      cantidad: cantidad.toString(),
      iva: producto.iva,
      descuento: porcentajeDescuento.toString(),
      ieps: producto.ieps,
      precio: precio.toString(),
      inverso: producto.preciosConImpuestosAgregados
    };
    ventaServices.obtenerImpuestos(filtros)
        .then(lstImpuestos => {
          let impuesto = lstImpuestos[0];
          producto.descuento           = impuesto.conceptoDescuentoSD;
          producto.importeTotalConIva  = impuesto.totalConDescuento;
          producto.importeTotal        = impuesto.subtotalSinDescuento;
          producto.importeIva          = impuesto.impuestoImporteIva;
          producto.importeIeps         = impuesto.impuestoImporteIeps;
          producto.importeUnitarioImp  = impuesto.precioRedondeoImpuetos;
          producto.importeUnitario     = impuesto.valorUnitarioSD;
          producto.precio              = impuesto.precio;
        }).catch(error => console.log(error));

    producto.descuentoPorcentaje = porcentajeDescuento * 100;
  
    return producto;
  },
}