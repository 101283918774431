//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VentaServices from "../../Services/VentaServices"
import VentasHelper  from "./VentasHelper";

const ventaServices = new VentaServices();
export default {
  name: "ventaMostrador",
  components: {
    BuscarClientesModal: () => import("./modales/buscarClientesModal"),
    BuscarProductosModal: () => import("./modales/buscarProductosModal"),
    ValidarCantidadesModal: () => import("./modales/validarCantidadesModal"),
    ValidarCrendencialesModal: () => import("../componentes/validarCredencialesModal"),
    MarcarFacturacionModal: () => import("../facturacion/modales/marcarFacturacionModal"),
    MostrarStockModal: () => import("./modales/mostrarStockModal"),
    ProductosConErrorModal: () => import("./modales/productosConErrorModal"),
  },
  data() {
    return {
      clienteObj: {},
      almacenes: [],
      tipoVenta: "Contado",
      tienda: JSON.parse(localStorage.getItem("tienda")),
      almacenSeleccionado: {},
      busquedaProducto: "",
      ventaDetalle: [],
      ventaTab: "acciones",
      descripcion: "",
      comentario: "",
      buscarClientesModal: false,
      isPublicoGeneral: false,
      tipoOperacionModal: false,
      almacenesModal: false,
      validarAlmacen: false,
      almacenProvisional: {},
      buscarProductosModal: false,
      productosConErrorModal: false,
      productoObj: {},
      descuentoVenta: "",
      configVentas: {},
      operacionCajaId: localStorage.getItem("operacionCajaId"),
      descuentoGlobalModal: false,
      requierePassword: false,
      validarCredenciales: {usuario: "", password: ""},
      descuentoVentaAplicado: 0,
      mostrarCantidadesModal: false,
      indexSeleccionado: "",
      productoDescuentoModal: false,
      descuentoProducto: "",
      cambiarPrecioListaModal: false,
      listaPrecios: [],
      precioListaSeleccionado: "",
      precioManualModal: false,
      precioManual: "",
      confirmacionModal: false,
      pinVendedorModal: false,
      pinVendedor: "",
      ventaFinalizada: false,
      folioVenta: "",
      datoFiscalSeleccionado: {},
      comentariosFacturacion: "",
      ventaId: "",
      fechaVigencia: "",
      eliminarProductoModal: false,
      descripcionProducto: "",
      editarDescripcionModal: false,
      confirmarAutorizacionModal: false,
      tipoAutorizacion: "",
      cajaObj: {},
      entregaProductos: false,
      modalEntregaProductos: false,
      labelVenta: "Ticket de venta",
      labelFinalizarVenta: "Finalizar venta",
      tituloModalVenta: 'Venta Finalizada',
      marcarFacturacionModal: false,
      mostrarStockModal: false,
      configImpuestos: {},
      preciosConImpuestosAgregados: false,
      venta0Modal: false,
      productosConError: [],
      productosConErrorTitle: "",
      productosConErrorLabel: "",
    }
  },
  async mounted() {
    this.cargaInicial();
  },
  created() {
    window.addEventListener('keyup', this.fireHotKeys)
  },
  computed: {
    totalVenta() {
      return _.sumBy(this.ventaDetalle, vd => Number(vd.importeTotalConIva));
    },
    totalIeps() {
      return _.sumBy(this.ventaDetalle, vd => Number(vd.importeIeps));
    },
    totalIva() {
      return _.sumBy(this.ventaDetalle, vd => Number(vd.importeIva));
    },
    subtotal() {
      return _.sumBy(this.ventaDetalle, vd => Number(vd.importeTotal));
    },
    totalImportesConImpuestos() {
      return _.sumBy(this.ventaDetalle, vd => Number(vd.importeUnitarioImp) * Number(vd.cantidad));
    },
    permisoGlobal() {
      return this.tipoVenta !== "Cotizacion" ? this.permisos.PERMISO_VENTAS_DESCUENTO_APLICAR : this.permisos.PERMISO_COTIZACION_DESCUENTO_APLICAR;
    },
    permisoProducto() {
      return this.tipoVenta !== "Cotizacion" ? this.permisos.PERMISO_VENTAS_DESCUENTO_PRODUCTO_APLICAR : this.permisos.PERMISO_COTIZACION_DESCUENTO_PRODUCTO_APLICAR;
    },
    permisoCambiarPrecioLista() {
      return this.tipoVenta !== "Cotizacion" ? this.permisos.PERMISO_VENTAS_PRECIO_LISTA_APLICAR : this.permisos.PERMISO_COTIZACION_PRECIO_LISTA_APLICAR;
    },
    permisoCambiarPrecioManual() {
      return this.tipoVenta !== "Cotizacion" ? this.permisos.PERMISO_VENTAS_PRECIO_MANUAL_APLICAR : this.permisos.PERMISO_COTIZACION_PRECIO_MANUAL_APLICAR;
    },
    mostrarImpuestos() {
      return this.configVentas['mostrar-importes-con-impuestos'] === "1";
    },
  },
  watch: {
    descuentoVenta(val) {
      this.requierePassword = val > (this.configVentas['descuento-maximo-no-passwd'] * 100);
    },
    descuentoProducto(val) {
      this.requierePassword = val > (this.configVentas['descuento-maximo-no-passwd'] * 100);
    },
    ventaFinalizada(value) {
      if (value && Number(this.totalVenta) > 0) this.confirmacionModal = true;
      if (value && Number(this.totalVenta) < 1) this.venta0Modal = true;
    },
    tipoVenta() {
      switch (this.tipoVenta) {
        case 'Contado':
          this.labelVenta          = 'Ticket de venta';
          this.labelFinalizarVenta = 'Finalizar venta';
          this.tituloModalVenta    = 'Venta Finalizada';
          break;
        case 'Credito':
          this.labelVenta          = 'Venta de crédito';
          this.labelFinalizarVenta = 'Finalizar venta a crédito';
          this.tituloModalVenta    = 'Venta Crédito Finalizada';
          break;
        case 'Cotizacion':
          this.labelVenta          = 'Cotizar';
          this.labelFinalizarVenta = 'Finalizar cotización';
          this.tituloModalVenta    = 'Cotización Finalizada';
          break;
        default:
          this.labelVenta          = 'Ticket de venta';
          this.labelFinalizarVenta = 'Finalizar venta';
          this.tituloModalVenta    = 'Venta Finalizada';
          break;
      }
    }
  },
  methods: {
    cargaInicial(){
      this.obtenerPublicoGeneral();
      this.cargarDatosIniciales();
      this.$nextTick(() => this.$refs.busqueda.focus());
    },
    fireHotKeys(e) {
      if (e.which === 119 && this.ventaDetalle.length > 0 && Number(this.totalVenta) > 0)
        this.confirmacionModal = true;
      else if (e.which === 119 && this.ventaDetalle.length > 0 && Number(this.totalVenta) < 1)
        this.venta0Modal = false;

      if (e.which === 113)
        this.$refs.busqueda.focus();
    },
    isEmpty: _.isEmpty,
    cargarDatosIniciales() {
      this.almacenes           = JSON.parse(localStorage.getItem("almacenes"));
      this.almacenSeleccionado = JSON.parse(localStorage.getItem("almacenSeleccionado"));
      this.configVentas        = JSON.parse(localStorage.getItem("configVentas"));        
      this.cajaObj             = JSON.parse(localStorage.getItem("cajaObj"));
      this.configImpuestos     = JSON.parse(localStorage.getItem("configImpuestos"));
    },
    obtenerPublicoGeneral() {
      this.clienteObj       = JSON.parse(localStorage.getItem("publicoGeneral"));
      this.isPublicoGeneral = true;
    },
    obtenerCliente(cliente) {
      this.clienteObj          = _.clone(cliente);
      this.buscarClientesModal = false;
      this.isPublicoGeneral    = false;
    },
    seleccionarTipoOperacion(tipo) {
      this.tipoVenta          = tipo;
      this.tipoOperacionModal = false;
    },
    obtenerAlmacen(almacen) {
      if (!_.isEmpty(this.ventaDetalle)) {
        this.validarAlmacen     = true;
        this.almacenProvisional = _.clone(almacen);
      } else {
        this.almacenSeleccionado = _.clone(almacen);
        this.almacenesModal      = false;
      }
    },
    cerrarValidarAlmacen() {
      this.validarAlmacen = false;
      this.almacenesModal = false;
    },
    aplicarAlmacen() {
      this.ventaDetalle        = [];
      this.almacenSeleccionado = _.clone(this.almacenProvisional);
      this.almacenProvisional  = {};
      this.cerrarValidarAlmacen();
    },
    agregarProducto(row) {
      let existe = _.filter(this.ventaDetalle, {productoId: row.productoId}),
          cantidadExiste = _.sumBy(this.ventaDetalle, vd => {if(vd.productoId === row.productoId) return Number(vd.cantidad)}),
          validar = Number(cantidadExiste) + Number(row.cantidad);
      if (!_.isEmpty(existe) && validar > Number(row.stock) && Number(row.facturarSinExistencias) === 0)
        return this.alertShow(`Se ha alcanzado el limite de stock permitido para este producto (${row.stock})`, "error");

      //INICIO VALIDACION DE STOCK ENTRE PRODUCTO PRINCIPAL Y PRODUCTOS ALIAS
      if (this.tipoVenta !== 'Cotizacion') {
        let productoStockRelacionado = row.stock ? Number(row.stock) : Number(row.stockLocalRelacionado),
            cantidadEquivalente = Number(row.cantidadEquivalente),
            cantidadAgregada = row.productoRelacionadoId != null ? cantidadEquivalente : Number(1),
            sinExistencias = row.facturar_sin_existencias != null ? row.facturar_sin_existencias : row.facturarSinExistencias,
            productoId = row.productoRelacionadoId != null ? row.productoRelacionadoId : row.productoId;

        let cantidadComparar = _.sumBy(
          this.ventaDetalle, p => 
            {
              let proId = p.productoRelacionadoId ? p.productoRelacionadoId : p.productoId;
              if (proId == productoId || proId == productoId) {
                return p.productoRelacionadoId ? Number(p.cantidadEquivalente) * Number(p.cantidad) : Number(p.cantidad)
              }
            }
        ) + cantidadAgregada;
        if (cantidadComparar > productoStockRelacionado && sinExistencias === 0){
            return this.alertShow('No es posible agregar producto a venta, ya que la cantidad que requiere es mayor al stock local.', 'error');
        }
      }
      //FIN VALIDACION

      this.buscarProductosModal = false;
      this.busquedaProducto     = "";
      this.calcularVentaProductos(row, true);

      this.$nextTick(() => {
        let index    = this.ventaDetalle.length - 1;
        let producto = this.ventaDetalle[index];
        this.selccionarProducto(producto, index);
        this.$refs.busqueda.focus();
      });
    },
    selccionarProducto(producto, index) {
      this.productoObj       = _.clone(producto);
      this.indexSeleccionado = index;
    },
    abrirModalDescuentoGlobal() {
      if (!this.permisos.existePermiso(this.permisoGlobal))
        return this.alertShow("No cuenta con los permisos necesarios para realizar esta acción (" + this.permisoGlobal + ")", "warning");
      this.descuentoGlobalModal = true;
      this.descuentoVenta       = !_.isEmpty(this.descuentoVentaAplicado) ? this.descuentoVentaAplicado : "";
    },
    validarDescuentoGlobalRules(val) {
      let descuentoMaximoPassword = this.configVentas['descuento-maximo-passwd'] * 100;
      if (val < 0) return "Debe ingresar un descuento mayor a 0";
      if (val > descuentoMaximoPassword) return "El descuento maximo permitido es del " + descuentoMaximoPassword + "%";
    },
    cerrarDescuentoGlboalModal() {
      this.descuentoGlobalModal = false;
      this.descuentoVenta       = "";
      this.cerrarAutorizacion();
    },
    validarDescuentoGlobal() {
      this.descuentoVentaAplicado = this.descuentoVenta;

      if (this.requierePassword) {
        let filtros = {
          permiso: this.permisoGlobal,
          usuario: this.validarCredenciales.usuario,
          password: this.validarCredenciales.password
        };

        return ventaServices.validarPermisoCredenciales(filtros)
          .then(() => this.aplicarDescuentoGlobal())
          .catch(error => this.alertShow(error, "error"));
      } else
        this.aplicarDescuentoGlobal();
    },
    aplicarDescuentoGlobal() {
      let clave           = "";
      let descuentoValido = this.ventaDetalle.reduce((valido, detalle) => {
        let precioMinimo = this.Decimal(detalle.precioMinimo);
        let precio       = this.Decimal(detalle.precio);
        let porcen       = this.Decimal(this.descuentoVenta);

        valido = VentasHelper.verificaPrecioMinProducto(precioMinimo, precio, porcen);
        clave  = detalle.claveInterna;

        return valido;
      }, true);

      if (!descuentoValido && !this.configVentas['permitir-exceder-precio-minimo-desc'])
        return this.alertShow('El descuento sobrepasa el precio minimo del producto con clave [' + clave + ']', "error");

      this.calcularVentaProductosGlobal();
      this.cerrarDescuentoGlboalModal();
    },
    eliminarProducto(index) {
      let array = _.cloneDeep(this.ventaDetalle);
      _.remove(array, (vd, indx) => vd.productoId === this.productoObj.productoId && index === indx);
      this.ventaDetalle          = array;
      this.eliminarProductoModal = false;
    },
    calcularVentaProductos(producto, nuevoIndex = false) {
      //this.ventaDetalle = VentasHelper.sumatoriaProductos(this.ventaDetalle, this.descuentoVentaAplicado, this.configImpuestos);                
      let nuevoProducto = VentasHelper.sumatoriaProductoIndividual(producto, this.descuentoVentaAplicado, this.configImpuestos);        
      // let ventaDetalle = _.cloneDeep(this.ventaDetalle);
      // this.ventaDetalle = [];
      // this.ventaDetalle = ventaDetalle;
      // this.ventaDetalle[this.indexSeleccionado] = nuevoProducto;        
      if(nuevoIndex === false) {
        this.ventaDetalle[this.indexSeleccionado] = nuevoProducto;
      }
      else {
        this.ventaDetalle.push(nuevoProducto);
      }
    },
    actualizarProducto(producto) {
      let ventaDetalle = _.cloneDeep(this.ventaDetalle);
      //this.ventaDetalle = [];

      let existe = _.filter(ventaDetalle, {productoId: producto.productoId}),
          cantidadExiste = _.sumBy(ventaDetalle, vd => {if(vd.productoId === producto.productoId) return Number(vd.cantidad)}) -1,
          validar = Number(cantidadExiste) + Number(producto.cantidad);

      /* if (!_.isEmpty(existe) && validar > Number(producto.stock) && producto.facturarSinExistencias === 0 && this.tipoVenta !== "Cotizacion")
        return this.alertShow(`Se ha alcanzado el limite de stock permitido para este producto (${producto.stock})`, "error"); */

      this.ventaDetalle           = ventaDetalle;
      this.ventaDetalle[this.indexSeleccionado].cantidadX   = producto.cantidadX;
      this.ventaDetalle[this.indexSeleccionado].cantidadY   = producto.cantidadY;
      this.ventaDetalle[this.indexSeleccionado].cantidadZ   = producto.cantidadZ;
      this.ventaDetalle[this.indexSeleccionado].cantidadW   = producto.cantidadW;
      this.ventaDetalle[this.indexSeleccionado].cantidad    = producto.cantidad;
      this.ventaDetalle[this.indexSeleccionado].descripcion = producto.descripcion;

      //INICIO VALIDACION DE STOCK ENTRE PRODUCTO PRINCIPAL Y PRODUCTOS ALIAS
      if (this.tipoVenta !== 'Cotizacion') {
        let productoStockRelacionado = producto.stock ? Number(producto.stock) : Number(producto.stockLocalRelacionado),
            cantidadEquivalente = Number(producto.cantidadEquivalente),
            cantidadAgregada = producto.productoRelacionadoId != null ? cantidadEquivalente : producto.cantidad,
            sinExistencias = producto.facturar_sin_existencias != null ? producto.facturar_sin_existencias : producto.facturarSinExistencias,
            productoId = producto.productoRelacionadoId != null ? producto.productoRelacionadoId : producto.productoId;

        let cantidadComparar = _.sumBy(
          this.ventaDetalle, p => 
            {
              let proId = p.productoRelacionadoId != null ? p.productoRelacionadoId : p.productoId;
              if (proId == productoId) {
                return p.productoRelacionadoId ? Number(p.cantidadEquivalente) * Number(p.cantidad) : Number(p.cantidad)
              }
            }
        );// + Number(cantidadAgregada);
        if (cantidadComparar > productoStockRelacionado && sinExistencias === 0){
            return this.alertShow('No es posible agregar producto a venta, ya que la cantidad que requiere es mayor al stock local.', 'error');
        }
      }
      //FIN VALIDACION

      // ventaDetalle.forEach((p, inx) => {
      //   if (p.productoId === producto.productoId && this.indexSeleccionado === inx) {
      //     p.cantidadX   = producto.cantidadX;
      //     p.cantidadY   = producto.cantidadY;
      //     p.cantidadZ   = producto.cantidadZ;
      //     p.cantidadW   = producto.cantidadW;
      //     p.cantidad    = producto.cantidad;
      //     p.descripcion = producto.descripcion;
      //   }
      // });

      this.mostrarCantidadesModal = false;
      this.calcularVentaProductos(this.ventaDetalle[this.indexSeleccionado]);
    },
    cargarDescuentoProducto(index) {
      if (!this.permisos.existePermiso(this.permisoProducto))
        return this.alertShow("No cuenta con los permisos necesarios para realizar esta acción (" + this.permisoProducto + ")", "warning");
      this.productoDescuentoModal = true;
      this.descuentoProducto      = this.productoObj.descuentoPorcentaje;
      this.indexSeleccionado = index;
      this.$nextTick(() => this.$refs.first.select());
    },
    cerrarDescuentoProducto() {
      this.productoDescuentoModal = false;
      this.descuentoProducto      = "";
      this.cerrarAutorizacion();
    },
    validarDescuentoProductoRules(val) {
      let descuentoMaximoPassword = this.configVentas['descuento-maximo-passwd'] * 100;
      if (val < 0) return "Debe ingresar un descuento mayor a 0";
      if (val > descuentoMaximoPassword) return "El descuento maximo permitido es del " + descuentoMaximoPassword + "%";
    },
    validarDescuentoProducto() {
      if (this.requierePassword) {
        let filtros = {
          permiso: this.permisoProducto,
          usuario: this.validarCredenciales.usuario,
          password: this.validarCredenciales.password
        };

        return ventaServices.validarPermisoCredenciales(filtros)
          .then(() => this.aplicarDescuentoProducto())
          .catch(error => this.alertShow(error, "error"));
      } else
        this.aplicarDescuentoProducto();
    },
    aplicarDescuentoProducto() {
      if (!_.isEmpty(this.descuentoVentaAplicado)) {
        this.descuentoVentaAplicado = 0;
        this.aplicarDescuentoGlobal();
      }
      let productoVenta = this.ventaDetalle[this.indexSeleccionado];
      let porcentaje    = this.descuentoProducto;
      let precio        = productoVenta.precio;
      let precioMinimo  = productoVenta.precioMinimo;

      if (!VentasHelper.verificaPrecioMinProducto(precioMinimo, precio, porcentaje))
        return this.alertShow('El descuento del producto sobrepasa el precio minimo.', "error");

      productoVenta.descuentoPorcentaje = porcentaje;        
      this.calcularVentaProductos(productoVenta);
      this.cerrarDescuentoProducto();
    },
    cargarPrecioListaModal() {
      if (!this.permisos.existePermiso(this.permisoCambiarPrecioLista)) {
        this.alertShow("No cuenta con los permisos necesarios para realizar esta acción (" + this.permisoCambiarPrecioLista + ")", "warning");
        return;
      }

      this.listaPrecios = VentasHelper.obtenerListaPreciosProducto(this.productoObj);

      this.precioListaSeleccionado = this.productoObj.precioLista;
      this.cambiarPrecioListaModal = true;
      this.$nextTick(() => this.$refs.first.focus());
    },
    cerrarPrecioListaModal() {
      this.cambiarPrecioListaModal = false;
      this.precioListaSeleccionado = "";
      this.cerrarAutorizacion();
    },
    validarPrecioLista() {
      if (Number(this.configVentas['precio-lista-passwd']) === 1) {
        let filtros = {
          permiso: this.permisoCambiarPrecioLista,
          usuario: this.validarCredenciales.usuario,
          password: this.validarCredenciales.password
        };

        return ventaServices.validarPermisoCredenciales(filtros)
          .then(() => this.aplicarPrecioListaProducto())
          .catch(error => this.alertShow(error, "error"));
      } else
        this.aplicarPrecioListaProducto();
    },
    aplicarPrecioListaProducto() {
      let productoVenta = this.productoObj,
          precio        = VentasHelper.precioCodigoProducto(productoVenta, this.precioListaSeleccionado),
          descuento     = productoVenta.descuentoPorcentaje,
          precioMinimo  = productoVenta.precioMinimo;

      if (!VentasHelper.verificaPrecioMinProducto(precioMinimo, precio, descuento))
        return this.alertShow(`El nuevo precio seleccionado es menor al precio minimo del producto (${this.accounting.formatNumber(precioMinimo)})`, "error");

      // let ventaDetalle = _.cloneDeep(this.ventaDetalle);
      // this.ventaDetalle = [];
      // ventaDetalle.forEach((p, inx) => {
      //   if (p.productoId === productoVenta.productoId && this.indexSeleccionado === inx) {
      //     p.precio      = precio;
      //     p.precioLista = Number(this.precioListaSeleccionado);
      //   }
      // });
      //this.ventaDetalle = ventaDetalle;
      this.ventaDetalle[this.indexSeleccionado].precio = precio;
      this.ventaDetalle[this.indexSeleccionado].precioLista = Number(this.precioListaSeleccionado);        
      this.calcularVentaProductos(this.ventaDetalle[this.indexSeleccionado]);
      this.cerrarPrecioListaModal();
    },
    cargarPrecioManualModal(index) {
      if (!this.permisos.existePermiso(this.permisoCambiarPrecioManual))
        return this.alertShow("No cuenta con los permisos necesarios para realizar esta acción (" + this.permisoCambiarPrecioManual + ")", "warning");

      this.precioManual      = this.productoObj.precio;
      this.indexSeleccionado = index;
      this.precioManualModal = true;
      this.preciosConImpuestosAgregados = false;
      this.$nextTick(() => this.$refs.first.select());
    },
    cerrarPrecioManualModal() {
      this.precioManualModal = false;
      this.precioManual      = "";
      this.preciosConImpuestosAgregados = false;
      this.cerrarAutorizacion();
    },
    validarPrecioManual() {
      if (Number(this.configVentas['precio-manual-passwd']) === 1) {
        let filtros = {
          permiso: this.permisoCambiarPrecioManual,
          usuario: this.validarCredenciales.usuario,
          password: this.validarCredenciales.password
        };

        return ventaServices.validarPermisoCredenciales(filtros)
          .then(() => this.aplicarPrecioManualProducto())
          .catch(error => this.alertShow(error, "error"));
      } else
      {          
        this.aplicarPrecioManualProducto();
      }
    },
    aplicarPrecioManualProducto() {
      let productoVenta = this.productoObj,
          precio        = this.precioManual,
          descuento     = productoVenta.descuentoPorcentaje,
          precioMinimo  = productoVenta.precioMinimo;

      if (!VentasHelper.verificaPrecioMinProducto(precioMinimo, precio, descuento))
        return this.alertShow(`El nuevo precio es menor al precio minimo del producto (${this.accounting.formatNumber(precioMinimo)})`, "error");

      //let ventaDetalle = _.cloneDeep(this.ventaDetalle);
      //this.ventaDetalle = [];
      // ventaDetalle.forEach((p, inx) => {
      //   if (p.productoId === productoVenta.productoId && this.indexSeleccionado === inx) {
      //     p.precio = precio;
      //     p.precioLista = 200;
      //     p.preciosConImpuestosAgregados = this.preciosConImpuestosAgregados;
      //   }
      // });        
      //this.ventaDetalle = ventaDetalle;

      this.ventaDetalle[this.indexSeleccionado].precio = precio;        
      this.ventaDetalle[this.indexSeleccionado].preciosConImpuestosAgregados = this.preciosConImpuestosAgregados;
      this.calcularVentaProductos(this.ventaDetalle[this.indexSeleccionado]);        
      this.cerrarPrecioManualModal();
    },
    validarFinalizarVenta() {
      this.confirmacionModal = false;
      if (Number(this.configVentas["solicitar-pin-finalizar"]) === 1)
        return this.pinVendedorModal = true;
      this.registrarVenta();
    },
    registrarVenta() {
      this.loader(true);
      let cajaTipoOperacion = Number(JSON.parse(localStorage.getItem("cajaObj")).tipo_operacion);
      let data = {
        almacenId: this.almacenSeleccionado.almacenId,
        clienteId: this.clienteObj.clienteId,
        tipoVenta: VentasHelper.obtenerTipoVenta(this.tipoVenta),
        comentarios: this.comentario,
        comentariosFacturacion: this.comentario,
        descripcion: this.descripcion,
        cajaId: this.cajaObj.caja_id || null,
        productos: VentasHelper.obtenerJsonProductos(this.ventaDetalle),
        aplicacion: "POS2",
      };

      if (!_.isEmpty(this.datoFiscalSeleccionado)) data.datoFiscalFacturar = this.datoFiscalSeleccionado.clienteFiscalId || null;
      if (!_.isEmpty(this.pinVendedor)) data.pinUsuario = this.pinVendedor;
      else data.registroAutorId = localStorage.getItem("usuarioId");

      return ventaServices.registrarVenta(data, true)
        .then(resp => {

          // Se valida status de operacion
          if(!resp.data.status){
            this.pinVendedorModal = false;
            this.productosConErrorModal = true;
            this.productosConError = resp.data.productos;
            this.productosConErrorTitle = "Productos por debajo del precio mínimo";
            this.productosConErrorLabel = "Para finalizar la venta modifique el precio antes de impuestos de los siguientes productos:";
            this.loader(false);
            return;
          }
          
          let response = resp.data,
              entregaProductos = response.entregaProductos;

          this.folioVenta       = response.folioVenta;
          this.ventaId          = response.ventaId;
          this.fechaVigencia    = response.fechaVigencia;
          this.entregaProductos = entregaProductos;
          this.pinVendedorModal = false;

          this.alertShow(`Se registro la ${VentasHelper.obtenerMsgTipoVenta(this.tipoVenta)} correctamente`, "exito");

          if (entregaProductos) this.abrirModalEntregaProductos();

          if (cajaTipoOperacion === 102 && this.tipoVenta !== 'Cotizacion' && !entregaProductos)
            this.$router.push({name: "cajaMostrador", params: {id: response.ventaId}});

          this.ventaFinalizada = true;
          this.loader(false);
        }).catch(error => {
          this.loader(false);
          this.alertShow(error, "error");
        });
    },
    limpiarVenta() {
      //location.reload();
      //this.obtenerPublicoGeneral();
      this.ventaDetalle           = [];
      this.descuentoVentaAplicado = 0;
      this.pinVendedor            = "";
      this.descripcion            = "";
      this.comentario             = "";
      this.tipoVenta              = "Contado";
      //this.cargarDatosIniciales();
      this.ventaFinalizada    = false;
      this.confirmacionModal  = false;
      this.almacenes          = [];
      this.listaPrecios       = [];
      this.clienteObj         = {};
      this.productoObj        = {};
      this.configVentas       = {};
      this.folioVenta         = "";
      this.busquedaProducto   = "";
      this.descuentoVenta     = "";
      this.indexSeleccionado  = "";
      this.cargaInicial();
    },
    obtenerPdf() {
      this.loader(true);
      let filtros = {ventaId: this.ventaId};

      return ventaServices.obtenerPdf(filtros)
        .then(res => {
          let linkSource   = `data:application/pdf;base64,${res.pdf}`;
          let downloadLink = document.createElement("a");
          let fileName     = res.nombre;

          downloadLink.href     = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
          this.alertShow(`PDF de ${VentasHelper.obtenerMsgTipoVenta(this.tipoVenta)} descargado correctamente.`, "exito");
        }).catch(error => this.alertShow(error, "error"))
        .then(() => this.loader(false));
    },
    cargarDescripcionProducto() {
      this.descripcionProducto    = this.productoObj.descripcionExtendida;        
      this.editarDescripcionModal = true;
      this.$nextTick(() => this.$refs.desc.focus());
    },
    actualizarDescripcionProducto() {
      // let ventaDetalle = _.cloneDeep(this.ventaDetalle);
      // this.ventaDetalle = [];
      // ventaDetalle.forEach((p, inx) => {
      //   if (p.productoId === this.productoObj.productoId && this.indexSeleccionado === inx) {
      //     p.descripcion = this.descripcionProducto;
      //   }
      // });        
      this.ventaDetalle[this.indexSeleccionado].descripcion = this.descripcionProducto;
      this.ventaDetalle[this.indexSeleccionado].descripcionExtendida = this.descripcionProducto;
      this.descripcionProducto    = "";
      this.editarDescripcionModal = false;
      // this.calcularVentaProductos();
    },
    abrirModalAutorizacion(tipo) {
      switch (tipo) {
        case 1:
          this.tipoAutorizacion = 1;
          if (this.requierePassword) return this.confirmarAutorizacionModal = true;
          return this.validarDescuentoGlobal();
        case 2:
          this.tipoAutorizacion = 2;
          if (this.requierePassword) return this.confirmarAutorizacionModal = true;
          return this.validarDescuentoProducto();
        case 3:
          this.tipoAutorizacion = 3;
          if (Number(this.configVentas['precio-lista-passwd']) === 1) return this.confirmarAutorizacionModal = true;
          return this.validarPrecioLista();
        case 4:
          this.tipoAutorizacion = 4;
          if (Number(this.configVentas['precio-manual-passwd']) === 1) return this.confirmarAutorizacionModal = true;
          return this.validarPrecioManual();
      }
    },
    validarAutorizacion(credenciales) {
      this.validarCredenciales = credenciales;
      switch (this.tipoAutorizacion) {
        case 1:
          return this.validarDescuentoGlobal();
        case 2:
          return this.validarDescuentoProducto();
        case 3:
          return this.validarPrecioLista();
        case 4:
          return this.validarPrecioManual();
      }
    },
    cerrarAutorizacion() {
      this.validarCredenciales        = {usuario: "", password: ""};
      this.tipoAutorizacion           = 0;
      this.confirmarAutorizacionModal = false;
    },
    abrirModalEntregaProductos() {
      this.modalEntregaProductos = true;
    },
    cerrarModalEntregaProductos() {
      this.modalEntregaProductos = false;
    },
    enviarSalidaLotesSeries() {
      this.$router.push({name: 'salidaLotesSeries', params: {id: this.ventaId}});
    },
    omitirSalidaLotesSeries() {
      this.modalEntregaProductos = false;
      //Si el tipo de operación es diferente de 101 redirecciona a caja
      if (Number(JSON.parse(localStorage.getItem("cajaObj")).tipo_operacion) !== 101 && !this.entregaProductos)
        this.$router.push({name: "cajaMostrador", params: {id: this.ventaId}});
    },
    obtenerDatoFiscal(datoFiscal) {
      this.datoFiscalSeleccionado = _.clone(datoFiscal);
      this.marcarFacturacionModal = false;
    },
    precioManualRules(valor) {
      let val = Number(valor),
          prod = this.productoObj,
          minimoPermitido = this.mostrarImpuestos ? Number(prod.precioMinimo) : Number(prod.precioMinimoImp);

      return  val >= minimoPermitido || "Precio invalido, minimo " + this.accounting.formatNumber(minimoPermitido);
    },
    editarDescripcionProducto(index) {
      this.productoObj = _.cloneDeep(this.ventaDetalle[index]);
      this.descripcionProducto  = this.productoObj.descripcionExtendida;
      this.indexSeleccionado = index;        
      this.editarDescripcionModal = true;
      this.$nextTick(() => this.$refs.desc.focus());
    },
    abrirModalEditarCantidades() {        
      this.mostrarCantidadesModal = true
    },
    calcularVentaProductosGlobal() {
      this.ventaDetalle = VentasHelper.sumatoriaProductos(this.ventaDetalle, this.descuentoVentaAplicado, this.configImpuestos);
    },
    mostrarImpuestoProductoVentaDetalle(detalle) {
      if (this.mostrarImpuestos == true && detalle.preciosConImpuestosAgregados == true) {
        if(detalle.preciosConImpuestosAgregados == false)
          return detalle.importeUnitarioImp;
        else 
          return this.productoTotalImporteConImpuesto(detalle.productoId);
          //return this.totalImportesConImpuestos;
      } else {
        return detalle.importeTotalConIva;
      }
    },
    productoTotalImporteConImpuesto(productoId) {
      let prod = this.ventaDetalle.filter(v => v.productoId == productoId)[0];
      return Number(prod.importeUnitarioImp) * Number(prod.cantidad);
    },
    cerrarModalProductosConError(){
      this.productosConErrorModal = false;
      this.productosConErrorTitle = "";
      this.productosConErrorLabel = "";
    },
  }
}
